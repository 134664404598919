<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
    }

    .page-break {
        page-break-before: always;
    }

    @page :footer {
        display: none;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

#title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#no-border {
    border: none;
}

td,
th {
    border: 2px solid #000000;
    text-align: left;
    padding: 8px;
}

.remove_border {
    margin-top: 80px;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

#info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <br>
        <p id="title">Complete PO Return List</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>SR Number</th>
                    <th>:</th>
                    <th>{{ code }}</th>
                </tr>
                <tr>
                    <th>SR Type</th>
                    <th>:</th>
                    <th>{{ selectedReturnType ? selectedReturnType.name : '' }}</th>
                </tr>
                <tr>
                    <th>PO Number</th>
                    <th>:</th>
                    <th>{{ purchase_order ? purchase_order.code : '' }}</th>
                </tr>
                <tr>
                    <th>GRR Number</th>
                    <th>:</th>
                    <th>{{ purchase_return_outbound ? purchase_return_outbound.code : '' }}</th>
                </tr>
                <tr>
                    <th>Supplier Code</th>
                    <th>:</th>
                    <th>{{ supplier ? supplier.code : '' }}</th>
                </tr>
                <tr>
                    <th>Supplier Name</th>
                    <th>:</th>
                    <th>{{ supplier ? supplier.name : '' }}</th>
                </tr>
                <tr>
                    <th>Supplier Address</th>
                    <th>:</th>
                    <th>{{ supplier ? supplier.address : '' }}</th>
                </tr>
                <tr>
                    <th>Warehouse</th>
                    <th>:</th>
                    <th>{{ warehouse_label }}</th>
                </tr>
                <tr>
                    <th>Notes</th>
                    <th>:</th>
                    <th>{{ note }}</th>
                </tr>
                <tr>
                    <th>Return Date</th>
                    <th>:</th>
                    <th>{{ date }}</th>
                </tr>
                <tr>
                    <th>Attachment</th>
                    <th>:</th>
                    <th>
                        <!-- ... -->
                    </th>
                </tr>
            </table>
        </div>
        <table class="table table-bordered" style="width:100%">
            <thead class="vs-table--thead">
                <tr>
                    <th width="20%">SKU Description</th>
                    <th width="5%">Returned Qty</th>
                    <th width="10%">HU</th>
                    <th width="10%">Batch</th>
                    <th width="15%">Warehouse Area</th>
                </tr>
            </thead>

            <tbody>
                <template v-for="(skuLine, index) in grrLines">
                    <tr style="padding-bottom: 5px" :key="index">
                        <td class="td vs-table--td align-top" style="padding: 5px"
                            :rowspan="skuLine.putaway_lines.length">
                            {{ skuLine.label }}
                        </td>

                        <!-- first putaway lines -->
                        <td class="td vs-table--td" style="padding: 5px" v-if="skuLine.putaway_lines.length">
                            {{ skuLine.putaway_lines[0].return_qty ? skuLine.putaway_lines[0].return_qty : 0 }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px" v-if="skuLine.putaway_lines.length">
                            {{ skuLine.putaway_lines[0].unit ? skuLine.putaway_lines[0].unit : '' }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px" v-if="skuLine.putaway_lines.length">
                            {{ skuLine.putaway_lines[0].batch ? skuLine.putaway_lines[0].batch.label : '' }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px" v-if="skuLine.putaway_lines.length">
                            {{ skuLine.putaway_lines[0].warehouse_area ? skuLine.putaway_lines[0].warehouse_area.name : '' }}
                        </td>
                    </tr>

                    <!-- added putaway lines -->
                    <template v-for="(putaways, idx) in grrLines[index].putaway_lines">
                        <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                            <td class="td vs-table--td" style="padding: 5px">
                                {{ putaways.return_qty ? putaways.return_qty : 0 }}
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                {{ putaways.unit ? putaways.unit : '' }}
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                {{ putaways.batch ? putaways.batch.label : '' }}
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                {{ putaways.warehouse_area ? putaways.warehouse_area.name : '' }}
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
        <br />
        <table class="remove_border">
            <tr>
                <th style="text-align:center;">Dibuat oleh</th>
                <th style="text-align:center;">Disiapkan oleh</th>
                <th style="text-align:center;">Diterima oleh</th>
            </tr>
            <tr>
                <th style="height: 50px;"></th>
                <th style="height: 50px;"></th>
                <th style="height: 50px;"></th>
            </tr>
            <tr>
                <th style="text-align:center;">Admin Logistic</th>
                <th style="text-align:center;">Picker</th>
                <th style="text-align:center;">Checker</th>
            </tr>
        </table>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    data() {
        return {
            id: 0,
            type: 0,
            returnId: 0,
            code: null,
            created_at: null,
            created_by: null,
            description: "",
            warehouse: null,
            warehouse_label: "",
            date: null,
            note: "",
            purchase_order_id: 0,
            purchase_order: null,
            supplier_id: 0,
            supplier: null,
            purchase_return_outbound: null,
            tax: null,
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            selectedReturnType: {
                value: false,
                name: "",
            },
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            grrLines: [
                {
                    sku_code: null,
                    item_name: null,
                    label: null,
                    putaway_lines: [
                        {
                            outbound_line_id: 0,
                            return_qty: 0,
                            unit: null,
                            price: null,
                            batch: null,
                            warehouse_area: null,
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        console.log("Route params:", this.$route.params);
        this.selectedReturnType = this.returnType[0]
        this.id = this.$route.params.id;
        this.type = Number(this.$route.query.t);
        this.returnId = Number(this.$route.query.r);
        console.log("type => ", this.type)
        if (this.id) {
            this.print()
            if (this.type == 1) {
                this.selectedReturnType = this.returnType[1]
            }
        }
    },
    methods: {
        async print() {
            await this.getData()
            // await window.print()
            /* try {
            } catch (error) {
                console.log(error)
            } */
        },
        async getData() {
            this.$vs.loading();
            if (this.type == 1) {
                const resp = await this.$http.get(this.$store.state.purchase.supplierReturn.baseUrlPath + "/edit-detail/" + this.returnId);
                if (resp.code == 200) {
                    this.id = resp.data.supplier_return.id
                    this.code = resp.data.supplier_return.code
                    this.created_at = resp.data.created_at
                    this.created_by = resp.data.personal.name
                    this.description = resp.data.supplier_return.description
                    this.warehouse = resp.data.warehouse
                    this.warehouse_label = this.warehouse.code + " - " + this.warehouse.name
                    this.date = this.formatDate(resp.data.supplier_return.date)
                    this.note = resp.data.supplier_return.note
                    this.supplier = resp.data.supplier
                    this.supplier_id = resp.data.supplier.id
                    this.purchase_return_outbound = resp.data.purchase_return_outbound
                    this.tax = resp.data.tax
                    this.grrLines = resp.data.grr_lines
                }
            } else {
                const resp = await this.$http.get("api/wms/v1/wms/purchase/po-return/edit-detail/" + this.id);
                if (resp.code == 200) {
                    this.id = resp.data.purchase_return.id
                    this.code = resp.data.purchase_return.code
                    this.created_at = resp.data.created_at
                    this.created_by = resp.data.personal.name
                    this.description = resp.data.purchase_return.description
                    this.warehouse = resp.data.warehouse
                    this.warehouse_label = this.warehouse.code + " - " + this.warehouse.name
                    this.date = this.formatDate(resp.data.purchase_return.date)
                    this.note = resp.data.purchase_return.note
                    this.purchase_order = resp.data.purchase_order
                    this.purchase_order_id = resp.data.purchase_order.id
                    this.supplier = resp.data.supplier
                    this.supplier_id = resp.data.supplier.id
                    this.purchase_return_outbound = resp.data.purchase_return_outbound
                    // this.gr_number = resp.data.gr_number
                    this.tax = resp.data.tax
                    this.grrLines = resp.data.grr_lines
                }
            }
            this.$vs.loading.close();
            /* try {
            } catch (error) {
                console.log(error);
            } finally {
                this.$vs.loading.close();
            } */
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>